<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card>
    <b-overlay
      :show="!rows"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="rows"
          ref="printMe"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: cumtomOldPackage -->
            <span
              v-if="props.column.field === 'customUser'"
              class="font-weight-bold"
            >
              <router-link :to="`/user/details/${props.row.uid}`">{{ props.row.customUser }}</router-link>
            </span>

            <!-- Column: cumtomOldPackage -->
            <span
              v-else-if="props.column.field === 'cumtomOldPackage'"
              class="font-weight-bold"
              v-html="props.row.cumtomOldPackage"
            />

            <!-- Column: cumtomNewPackage -->
            <span
              v-else-if="props.column.field === 'cumtomNewPackage'"
              class="font-weight-bold"
              v-html="props.row.cumtomNewPackage"
            />

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','15','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'

// import axios from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BCard,
    BFormSelect,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      pageLength: 20,
      columns: [
        {
          label: 'ID',
          field: 'id',
          hidden: true,
        },
        {
          label: 'User',
          field: 'customUser',
        },
        {
          label: 'Old Package',
          field: 'cumtomOldPackage',
        },
        {
          label: 'New Package',
          field: 'cumtomNewPackage',
        },
        {
          label: 'Upgrade Status',
          field: 'upgrade_status_text',
        },
        {
          label: 'Trasaction Date',
          field: 'customDateUpgrade',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    userList() {
      return this.$store.state.packages.userList
    },
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.userList) {
        this.getRows()
        clearInterval(refreshId)
      }
    }, 500)
  },
  created() {
    this.fetchUserListUpgrades()
  },

  methods: {
    fetchUserListUpgrades() {
      this.$store.dispatch('packages/fetchUserListUpgrades')
        .catch(error => { console.log(error) })
    },

    getRows() {
      const cnt = this.userList.length
      let i = 0

      for (i = 0; i < cnt; i += 1) {
        const item = this.userList[i]
        const oldMethod = item.old_paymode === 'conversions' ? item.old_conversions.toLocaleString() : this.numberToString(item.old_clicks)

        const row = {
          id: item.id,
          uid: item.uid,
          upgrade_status_text: item.upgrade_status_text,
          customUser: `${item.forename} ${item.surname}`,
          cumtomOldPackage: `<b>${item.old_name} (€ ${item.old_price})</b> <br />${oldMethod} ${item.old_paymode}`,
          cumtomNewPackage: `<b>${item.new_name} (€ ${item.new_price})</b> <br />${item.new_conversions.toLocaleString()} conversions +<br /> ${this.numberToString(item.new_clicks)} impressions (clicks) included`,
          customDateUpgrade: moment(new Date(item.date_upgraded)).format('DD MMM YYYY'),
        }

        this.rows.push(row)
      }
    },

    numberToString(x) {
      const str = this.suffixNumberString(x)
      let numStr = x.toString() // eslint-disable-line
      if (str === 'million') numStr = `${numStr.slice(0, -6)} ${str}` // eslint-disable-line
      else if (str === 'thousand') numStr = `${numStr.slice(0, -3)} ${str}` // eslint-disable-line
      return numStr
    },

    suffixNumberString(x) { // eslint-disable-line
      x = Number(x) // eslint-disable-line
      if (x >= 1000000 && x <= 999999999) return 'million' // eslint-disable-line
      else if (x >= 1000  && x <= 999999) return 'thousand' // eslint-disable-line
    },

    sortFnID(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Number(rowX.id)
      const value2 = Number(rowY.id)
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnRegDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.created_at))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.created_at))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnPayDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.updated_at))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.updated_at))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnAmount(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = this.getAmount(rowX)
      const value2 = this.getAmount(rowY)
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    getAmount(item) {
      const value = parseFloat(item.ammount)
      return value
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.modal .modal-header .close{
  margin: 2px
}
</style>
